import { action, makeAutoObservable, observable } from "mobx"
import { createContext } from "react"
import { findCookieByName, gateUrl } from "../utils"

export interface Reminder {
  id: string,
  title: string,
  startAt: number,
  delta: number,
}

class ReminderStore {

  constructor() {
    makeAutoObservable(this)
  }

  @observable reminders: Reminder[] = []

  @action fetchReminders = async () => {
    try {
      const response = await fetch(`${gateUrl}/api/v1/reminders`, {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'authorization': 'Bearer ' + findCookieByName('Authorization')
        },
      })
      const json = await response.json()
      this.reminders = json.payload
    } catch (error) {
      console.log(error)
    }
  }
}

export default createContext(new ReminderStore())
