import { action, makeAutoObservable, observable } from "mobx"
import { createContext } from "react"
import { findCookieByName, gateUrl } from "../utils"

export type User = {
    email: string,
    username: string,
}

class UserStorage {

    constructor() {
        makeAutoObservable(this)
    }

    @observable
    user: User | undefined

    @action
    fetchUser = async () => {
        try {
            const response = await fetch(`${gateUrl}/api/v1/auth`, {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'authorization': 'Bearer ' + findCookieByName('Authorization')
                },
            })
            const json = await response.json()
            this.user = json.payload
            return this.user
        } catch (_) { }
    }
}

export default createContext(new UserStorage())
