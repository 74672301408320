import { useContext, useEffect, useState } from 'react';
import Typography from '@mui/joy/Typography';
import { List, Accordion, AccordionDetails, AccordionGroup, AccordionSummary, Box, Chip, IconButton, Input, Stack, Sheet, Modal, ModalClose, Button } from '@mui/joy';
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import { AddRounded } from "@mui/icons-material";
import TaskListItem from './TaskListItem';
import { findCookieByName, toggleSidebar } from '../../../utils';
import { observer } from "mobx-react-lite";
import ListStorage, { AppList } from "../../../storage/ListStorage";
import TaskStorage, { NewTask } from "../../../storage/TaskStorage";
import { v4 as uuid } from 'uuid';
import { DateTime } from 'luxon';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';

function ListsPane() {

    const { tasks, addTask } = useContext(TaskStorage)
    const { lists, selectedListId, todayOnly, setTodayOnly, updateList, deleteList } = useContext(ListStorage)
    const [newTask, setNewTask] = useState<NewTask>({ id: '', title: '', list: '', details: '', allDay: true })
    const [activeTaskAccordionExpanded, setActiveTaskAccordionExpanded] = useState(true)
    const [editListModalOpen, setEditListModalOpen] = useState(false)
    const selectedListTitle = lists.find(l => l.id == selectedListId) ? lists.find(l => l.id == selectedListId)!.title : ""
    const [editListModalListTitle, setEditListModalListTitle] = useState(selectedListId)

    useEffect(() => setEditListModalListTitle(selectedListTitle), [selectedListId])

    const changeTaskTitleHandler = (text: string): string => {
        if (!text) return text;
        return text[0].toUpperCase() + text.slice(1);
    }

    const newTaskSaveKeyHandler = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            saveNewTask()
            setNewTask({ id: '', title: '', list: '', details: '', allDay: true })
        }
    }

    const saveNewTask = () => {
        const task: NewTask = {
            id: uuid(),
            title: newTask.title,
            details: '',
            list: findCookieByName('list')!,
            startAt: undefined,
            allDay: true,
            reminder: undefined,
            chain: undefined,
        }
        addTask(task)
    }

    const editListButtonClickHandler = () => {
        setEditListModalOpen(true)
    }

    const editListModalSaveButtonClickHandler = () => {
        updateList({
            ...(lists.find(l => l.id === selectedListId) as AppList),
            title: editListModalListTitle as string
        })
        setEditListModalOpen(false)
    }

    const editListModalDeleteButtonClickHandler = () => {
        deleteList(lists.find(l => l.id === selectedListId) as AppList)
        setEditListModalOpen(false)
    }

    return (
        <Sheet
            sx={{
                borderRight: '1px solid',
                borderColor: 'divider',
                height: '100dvh',
                // height: 'calc(100dvh - var(--Header-height))',
                overflowY: 'auto',
            }}
        >
            <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent="space-between"
                p={2}
                pb={1.5}
            >
                <Stack direction="row">
                <IconButton
                    sx={{ display: { md: 'none' } }}
                    onClick={() => toggleSidebar()}
                    variant="outlined"
                    color="neutral"
                    size="sm"
                >
                    <MenuRoundedIcon />
                </IconButton>
                <Typography
                    fontSize={{ xs: 'md', md: 'lg' }}
                    component="h1"
                    fontWeight="lg"
                    endDecorator={
                        <Chip
                            variant="soft"
                            color="primary"
                            size="md"
                            slotProps={{ root: { component: 'span' } }}
                        >
                            {tasks.filter(t => t?.list === selectedListId && !t.completed).length}
                        </Chip>
                    }
                    sx={{ marginLeft: '8px' }}
                >
                    {lists?.find(l => l.id === selectedListId)?.title}
                </Typography>
                </Stack>

                <IconButton
                    variant="plain"
                    aria-label="edit"
                    color="neutral"
                    size="sm"
                    sx={{ display: { sm: 'unset' } }}
                    // sx={{ display: { xs: 'none', sm: 'unset' } }}
                    onClick={editListButtonClickHandler}
                >
                    <EditNoteRoundedIcon />
                </IconButton>
                {/* Edit list modal */}
                <Modal
                    aria-labelledby="modal-title"
                    aria-describedby="modal-desc"
                    open={editListModalOpen}
                    onClose={() => setEditListModalOpen(false)}
                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                    <Sheet
                        variant="outlined"
                        sx={{
                            maxWidth: 500,
                            borderRadius: 'md',
                            p: 3,
                            boxShadow: 'lg',
                        }}
                    >
                        <ModalClose variant="plain" sx={{ m: 1 }} />
                        <Typography
                            component="h2"
                            id="modal-title"
                            level="h4"
                            textColor="inherit"
                            fontWeight="lg"
                            mb={1}
                        >
                            Edit list
                        </Typography>
                        <Input
                            value={editListModalListTitle}
                            onChange={(e) => setEditListModalListTitle(e.target.value)}
                        />
                        <Stack direction="row" sx={{ marginTop: '1rem' }}>
                            <Button onClick={editListModalDeleteButtonClickHandler} color="danger">Delete</Button>
                            <Box sx={{ minWidth: 0, flex: 1 }}>  </Box>
                            <Button onClick={() => setEditListModalOpen(false)} color="neutral">Cancel</Button>
                            <Button onClick={editListModalSaveButtonClickHandler} sx={{ marginLeft: '8px' }}>Save</Button>
                        </Stack>
                    </Sheet>
                </Modal>
            </Stack>
            <Box sx={{ px: 2, pb: 1.5 }}>
                <Input
                    size="sm"
                    startDecorator={<AddRounded />}
                    placeholder="Add new task"
                    aria-label="Search"
                    value={newTask ? newTask.title : ''}
                    onChange={e => setNewTask({ ...newTask!, title: changeTaskTitleHandler(e.currentTarget.value) })}
                    onKeyUp={e => {
                        newTaskSaveKeyHandler(e)
                    }}
                />
            </Box>
            <AccordionGroup variant="plain" size="sm" disableDivider sx={{ marginBottom: '8px' }}>
                <Accordion
                    expanded={activeTaskAccordionExpanded}
                    onChange={() => setActiveTaskAccordionExpanded(!activeTaskAccordionExpanded)}
                >
                    <AccordionSummary>Active</AccordionSummary>
                    <AccordionDetails>
                        <List
                            sx={{
                                py: 0,
                                '--ListItem-paddingX': '1rem',
                            }}
                        >
                            {todayOnly
                                ?
                                (tasks
                                    .filter(t => !t.completed)
                                    .filter(t => t.startAt)
                                    .filter(t => t.startAt! > DateTime.now().startOf('day').toMillis())
                                    .filter(t => t.startAt! < DateTime.now().endOf('day').toMillis())
                                    .sort((a, b) => a.createdAt - b.createdAt)
                                    .reverse()
                                    .map(task =>
                                        <TaskListItem
                                            key={task.id}
                                            task={task}
                                        />
                                    ))
                                :
                                (tasks
                                    .filter(t => !t.completed && t.list === selectedListId)
                                    // .sort((a, b) => a.createdAt - (b.chain ? taskChains.find(chain => chain.id === b.chain)!.createdAt : b.createdAt))
                                    .slice()
                                    .sort((a, b) => a.createdAt - b.createdAt)
                                    .reverse()
                                    .map(task =>
                                        <TaskListItem
                                            key={task.id}
                                            task={task}
                                        />
                                    ))}
                        </List>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary>Completed</AccordionSummary>
                    <AccordionDetails>
                        <List
                            sx={{
                                py: 0,
                                '--ListItem-paddingX': '1rem',
                            }}
                        >
                            {todayOnly
                                ?
                                (tasks
                                    .filter(t => t.completed)
                                    .filter(t => t.startAt)
                                    .filter(t => t.startAt! > DateTime.now().startOf('day').toMillis())
                                    .filter(t => t.startAt! < DateTime.now().endOf('day').toMillis())
                                    .sort((a, b) => a.createdAt - b.createdAt)
                                    .reverse()
                                    .map(task =>
                                        <TaskListItem
                                            key={task.id}
                                            task={task}
                                        />
                                    ))
                                :
                                (tasks
                                    .filter(t => t.completed && t.list === selectedListId)
                                    .slice()
                                    .sort((a, b) => a.completedAt! - b.completedAt!)
                                    .reverse()
                                    .map(task =>
                                        <TaskListItem
                                            key={task.id}
                                            task={task}
                                        />
                                    ))
                            }
                        </List>
                    </AccordionDetails>
                </Accordion>
            </AccordionGroup>

        </Sheet>
    );
}

export default observer(ListsPane)
