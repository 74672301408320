import { observer } from "mobx-react-lite";
import { Fragment, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import ReminderStore, { Reminder } from "../../storage/ReminderStore";
import { spawn } from "child_process";
import { addCookie, clearCookies, gateUrl, findCookieByName } from "../../utils";

function RemindersScreen() {

    const navigate = useNavigate()
    const { reminders, fetchReminders } = useContext(ReminderStore)

    useEffect(() => {
        fetchReminders()
    }, [])

    const deleteReminderHandler = (reminder: Reminder) => {
        try {
            fetch(`${gateUrl}/api/v1/reminders/${reminder.id}`, {
              method: 'DELETE',
              headers: {
                'content-type': 'application/json',
                'authorization': 'Bearer ' + findCookieByName('Authorization')
              },
            }).then(_ => fetchReminders())
          } catch (error) {
            console.log(error)
          }
    }

    return (
        <Fragment>
            <div className="container">
                <div className="row my-2">
                    <div className="col-lg-4"></div>
                    <div className="col-lg-4"><span className="bi bi-arrow-left me-2" onClick={_ => navigate('/')} role="button"></span><span>Напоминания</span></div>
                    <div className="col-lg-4"></div>
                </div>
                <div className="row">
                    <div className="col-lg-4"></div>
                    <div className="col-lg-4">
                        <ul className='list-group list-group-flush mt-2'>
                            {reminders.length > 0 ? reminders.map((reminder, i) => (
                                <li className='list-group-item text-primary d-flex' role="button" key={reminder.id}>
                                    <span style={{ color: 'black' }}>{reminder.title} - {DateTime.fromMillis(reminder.startAt).toFormat("yyyy-MM-dd HH:mm")}</span>
                                    <i onClick={_ => deleteReminderHandler(reminder)} className="bi bi-trash ms-auto"/>
                                </li>
                            )): <span className="text-muted text-center">Нет активных напоминаний</span>}
                        </ul>
                    </div>
                    <div className="col-lg-4"></div>
                </div>
            </div>
        </Fragment>
    );
}

export default observer(RemindersScreen)
