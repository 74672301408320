import './App.css';
import Login from "./page/login/Login";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Root from "./page/root/Root";
import Web from "./page/web/Web";
import Meter from './page/meter/Meter';
import RemindersScreen from './page/reminders/RemindersScreen';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/login' element={<Login />} />
                <Route path='/' element={<Root />} />
                <Route path='/web' element={<Web />} />
                <Route path='/meters' element={<Meter />} />
                <Route path='/reminders' element={<RemindersScreen />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
