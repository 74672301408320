import { action, computed, makeAutoObservable, observable } from "mobx"
import { createContext } from "react"
import { addCookie, findCookieByName, gateUrl } from "../utils"

export type AppList = {
    id: string,
    title: string,
    main: boolean,
    position: number,
}

class ListStore {

    constructor() {
        makeAutoObservable(this)
    }

    @observable
    lists: AppList[] = []

    @observable
    selectedListId: string = ""

    @action
    setSelectedListId = (id: string) => {
        this.selectedListId = id;
    }

    @observable
    todayOnly: boolean = false

    @action
    setTodayOnly = (showTodayOnly: boolean) => {
        this.todayOnly = showTodayOnly
    }

    @action
    fetchLists = () => {
        fetch(`${gateUrl}/api/v1/lists`, {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
                'authorization': 'Bearer ' + findCookieByName('Authorization')
            },
        })
            .then(response => response.json())
            .then(json => {
                this.lists = json.payload
                if (!findCookieByName("list")) {
                    const mainListId = json.payload.find((list: AppList) => list.main).id
                    addCookie(`list=${mainListId}`)
                    this.selectedListId = mainListId
                } else {
                    this.selectedListId = findCookieByName("list")!
                }
            })
            .catch(error => console.log(error))
    }

    @action
    addList = (title: string) => {
        fetch(`${gateUrl}/api/v1/lists`, {
            method: 'POST',
            body: JSON.stringify({ title }),
            headers: {
                'content-type': 'application/json',
                'authorization': 'Bearer ' + findCookieByName('Authorization')
            },
        })
            .then(_ => this.fetchLists())
            .catch(error => {
                console.log(error)
            })
    }

    @action
    updateList = (list: AppList) => {
        fetch(`${gateUrl}/api/v1/lists/${list.id}`, {
            method: 'PUT',
            body: JSON.stringify({ title: list.title }),
            headers: {
                'content-type': 'application/json',
                'authorization': 'Bearer ' + findCookieByName('Authorization')
            },
        })
            .then(_ => this.fetchLists())
            .catch(error => {
                console.log(error)
            })
    }

    @action
    deleteList = (list: AppList) => {
        this.lists = this.lists.filter(l => l.id !== list.id)
        // If current list is deleted, back to main list
        if (findCookieByName('list') === list.id) {
            addCookie('list=' + this.lists.find(l => l.main)?.id)
        }
        fetch(`${gateUrl}/api/v1/lists/${list.id}`, {
            method: 'DELETE',
            headers: {
                'content-type': 'application/json',
                'authorization': 'Bearer ' + findCookieByName('Authorization')
            },
        })
            .then(_ => this.fetchLists())
            .catch(error => {
                console.log(error)
            })
    }

    @computed
    get infoLists() {
        return {
            total: this.lists.length,
        }
    }
}

export default createContext(new ListStore())
