import { ChangeEvent, useContext, useState } from 'react';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import { toggleMessagesPane } from '../../../utils';
import TaskStorage from "../../../storage/TaskStorage";
import { observer } from "mobx-react-lite";
import { DateTime } from "luxon";
import { Checkbox, Divider, Dropdown, IconButton, Menu, MenuButton, Stack } from "@mui/joy";
import TaskListDatePicker from './TaskListDatePicker';

function TaskDetailsHeader() {

    const { tasks, selectedTask, setSelectedTask, updateTaskStatus } = useContext(TaskStorage)
    const task = tasks.find(t => t.id === selectedTask)
    const [datePickerOpen, setDatePickerOpen] = useState(false)

    const startAt = tasks?.find(t => t.id === selectedTask)?.startAt
    const formattedDate = startAt && DateTime.fromMillis(startAt).setLocale('ru').toFormat("LLL dd, HH:mm")

    const backButtonHandler = () => {
        toggleMessagesPane()
        setTimeout(() => {
            if (selectedTask && selectedTask.length > 0) {
                setSelectedTask("")
            }
        }, 50)
    }

    const handleTaskStatusCheckbox = (event: ChangeEvent<HTMLInputElement>): void => {
        updateTaskStatus(task!.id)
    }

    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            sx={{
                borderBottom: '1px solid',
                borderColor: 'divider',
                backgroundColor: 'background.body',
            }}
            py={{ xs: 2, md: 2 }}
            px={{ xs: 1, md: 2 }}
        >
            <Stack direction="row" spacing={{ xs: 1, md: 2 }} alignItems="center">
                {/* Back button */}
                <IconButton
                    variant="plain"
                    color="neutral"
                    size="sm"
                    sx={{
                        display: { xs: 'inline-flex', sm: 'none' },
                    }}
                    onClick={backButtonHandler}
                >
                    <ArrowBackIosNewRoundedIcon />
                </IconButton>
                {/* Checkbox with task status */}
                <Checkbox
                    variant="outlined"
                    checked={tasks?.find(t => t.id === selectedTask)?.completed}
                    onChange={handleTaskStatusCheckbox}
                />
                <Divider orientation={"vertical"} />
                {/* Date button or date */}
                <Dropdown open={datePickerOpen} onOpenChange={() => setDatePickerOpen(prev => !prev)}>
                    <MenuButton variant="plain">
                        {startAt ? formattedDate : "Due date"}
                    </MenuButton>
                    <Menu>
                        <TaskListDatePicker setDatePickerOpen={setDatePickerOpen} />
                    </Menu>
                </Dropdown>
            </Stack>
            <Stack spacing={1} direction="row" alignItems="center">
                <IconButton size="sm" variant="plain" color="neutral">
                    <MoreVertRoundedIcon />
                </IconButton>
            </Stack>
        </Stack>
    );
}

export default observer(TaskDetailsHeader)
