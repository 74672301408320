import TaskDetailsHeader from './TaskDetailsHeader';
import TaskDetailsContent from "./TaskDetailsContent";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import { Fragment, useContext } from "react";
import TaskStorage, { Task } from "../../../storage/TaskStorage";
import { observer } from "mobx-react-lite";
import ListStorage, { AppList } from "../../../storage/ListStorage";
import { toggleMessagesPane } from "../../../utils";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import { Avatar, Box, Divider, Dropdown, IconButton, Menu, MenuButton, MenuItem, Sheet, Stack, Typography } from '@mui/joy';
import { Apps } from '@mui/icons-material';

function TaskDetailsPane() {

    const { tasks, selectedTask, updateTask } = useContext(TaskStorage)
    const { lists } = useContext(ListStorage)

    const chooseListFromTaskDetailsHandler = (destinationList: AppList) => {
        const task: Task = tasks.find(t => t.id === selectedTask)!
        updateTask({
            ...task,
            list: destinationList.id
        })
    }

    return (
        <Sheet
            sx={{
                // height: {xs: '100dvh', lg: '100dvh'},
                height: { xs: 'calc(100dvh - var(--Header-height))', sm: '100dvh', md: '100dvh', lg: '100dvh' },
                display: 'flex',
                flexDirection: 'column',
                // backgroundColor: 'background.level1',
            }}
        >
            {/* Header with checkbox*/}
            {selectedTask && selectedTask.length > 0 ? (
                <Fragment>
                    <TaskDetailsHeader />
                    <Box
                        sx={{
                            minHeight: 0,
                            overflow: 'hidden auto',
                            flexGrow: 1,
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <TaskDetailsContent />
                    </Box>
                    <Divider />
                    {/* Sticky footer for task details */}
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', padding: '4px' }}>
                        {/* Dropdown with lists */}
                        <Dropdown>
                            <MenuButton startDecorator={<Apps />} variant="plain">
                                {lists?.find(l => l.id === tasks?.find(t => t.id === selectedTask)?.list)?.title}
                            </MenuButton>
                            <Menu>
                                {
                                    lists.map(list => 
                                        <MenuItem key={list.id} onClick={() => chooseListFromTaskDetailsHandler(list)}>{list.title}</MenuItem>
                                    )
                                }
                            </Menu>
                        </Dropdown>
                        <Box sx={{ minWidth: 0, flex: 1 }}>
                        </Box>
                        <IconButton size="sm" variant="plain" color="neutral">
                            <LogoutRoundedIcon />
                        </IconButton>
                    </Box>
                </Fragment>
            ) : <Box sx={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Stack>
                    <IconButton
                        variant="plain"
                        color="neutral"
                        size="sm"
                        sx={{
                            display: { xs: 'inline-flex', sm: 'none' },
                        }}
                        onClick={() => toggleMessagesPane()}
                    >
                        <ArrowBackIosNewRoundedIcon />
                    </IconButton>
                    <Avatar
                        sx={{ alignSelf: 'center' }}
                        variant="outlined"
                        size="lg"
                        src="/listok-logo.png"
                    />
                    <Typography alignContent="center">Click task title to view details</Typography>
                </Stack>
            </Box>}
        </Sheet>
    );
}

export default observer(TaskDetailsPane)
