import Sheet from '@mui/joy/Sheet';
import TaskDetailsPane from './TaskDetailsPane';
import ListsPane from './ListsPane';

export default function TaskListAndTaskDetailsPane() {
    return (
        <Sheet
            sx={{
                flex: 1,
                width: '100%',
                mx: 'auto',
                pt: { xs: 'var(--Header-height)', sm: 0 },
                display: 'grid',
                gridTemplateColumns: {
                    xs: '1fr',
                    sm: 'minmax(min-content, min(30%, 400px)) 1fr',
                },
            }}
        >
            <Sheet
                sx={{
                    position: {
                        xs: 'fixed',
                        sm: 'sticky',
                    },
                    transform: {
                        xs: 'translateX(calc(100% * (var(--MessagesPane-slideIn, 0) - 1)))',
                        sm: 'none',
                    },
                    transition: 'transform 0.3s, width 0.3s',
                    zIndex: 100,
                    width: '100%',
                    // top: 52,
                }}
            >
                <ListsPane />
            </Sheet>
            <TaskDetailsPane />
        </Sheet>
    );
}
