import { Dispatch, Fragment, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react';
import { Box, Sheet, GlobalStyles, IconButton, Typography, listItemButtonClasses, List, ListItem, ListItemButton, ListItemContent, Chip, Divider, Avatar } from '@mui/joy';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import SupportRoundedIcon from '@mui/icons-material/SupportRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import BadgeRoundedIcon from '@mui/icons-material/BadgeRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ColorSchemeToggle from './ColorSchemeToggle';
import { addCookie, closeSidebar, findCookieByName, toggleMessagesPane, toggleSidebar } from '../../../utils';
import ListStorage, { AppList } from "../../../storage/ListStorage";
import TaskStorage from "../../../storage/TaskStorage";
import UserStorage from "../../../storage/UserStorage";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";

function Toggler({
    defaultExpanded = false,
    renderToggle,
    children,
}: {
    defaultExpanded?: boolean;
    children: ReactNode;
    renderToggle: (params: {
        open: boolean;
        setOpen: Dispatch<SetStateAction<boolean>>;
    }) => ReactNode;
}) {
    const [open, setOpen] = useState(defaultExpanded);
    return (
        <Fragment>
            {renderToggle({ open, setOpen })}
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateRows: open ? '1fr' : '0fr',
                    transition: '0.2s ease',
                    '& > *': {
                        overflow: 'hidden',
                    },
                }}
            >
                {children}
            </Box>
        </Fragment>
    );
}

function Sidebar() {

    const navigate = useNavigate()
    const listIdFromCookies = findCookieByName("list")
    const { tasks, selectedTask, setSelectedTask } = useContext(TaskStorage)
    const { lists, fetchLists, selectedListId, setSelectedListId, todayOnly, setTodayOnly } = useContext(ListStorage)
    const { fetchTasks } = useContext(TaskStorage)
    const { fetchUser, user } = useContext(UserStorage)
    const [todayListSelected, setTodayListSelected] = useState(false)
    const [inboxListSelected, setInboxListSelected] = useState(false)

    useEffect(() => {
        console.log("selected " + selectedListId)
        console.log("test " + lists.find(l => l.main)?.id)
        if (selectedListId === lists.find(l => l.main)?.id) {
            setInboxListSelected(true)
        }
    }, [selectedListId])

    useEffect(() => {
        if (!selectedTask || selectedTask.length === 0) {
            toggleMessagesPane()
        }
        fetchUser().then(u => {
            if (!u) navigate('/login')
        })
        fetchLists()
        fetchTasks()
    }, [])

    const chooseListHandler = (l: AppList) => {
        setTodayListSelected(false)
        addCookie('list=' + l.id)
        setSelectedListId(l.id)
        setSelectedTask("")
        toggleSidebar()
        setTodayOnly(false)
        setInboxListSelected(false)
    }

    const todayListClickHandler = () => {
        setTodayOnly(true)
        setTodayListSelected(true)
        setSelectedTask("")
        toggleSidebar()
        setInboxListSelected(false)
    }

    const inboxListClickHandler = () => {
        const inboxListId = lists.find(l => l.main)!.id
        setTodayListSelected(false)
        addCookie('list=' + inboxListId)
        setSelectedListId(inboxListId)
        setSelectedTask("")
        toggleSidebar()
        setTodayOnly(false)
        setInboxListSelected(true)
    }

    return (
        <Sheet
            className="Sidebar"
            sx={{
                position: {
                    xs: 'fixed',
                    md: 'sticky',
                },
                transform: {
                    xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
                    md: 'none',
                },
                transition: 'transform 0.4s, width 0.4s',
                zIndex: 10000,
                height: '100dvh',
                width: 'var(--Sidebar-width)',
                top: 0,
                p: 1.5,
                py: 2,
                flexShrink: 0,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                borderRight: '1px solid',
                borderColor: 'divider',
            }}
        >
            <GlobalStyles
                styles={(theme) => ({
                    ':root': {
                        '--Sidebar-width': '220px',
                        [theme.breakpoints.up('lg')]: {
                            '--Sidebar-width': '240px',
                        },
                    },
                })}
            />
            <Box
                className="Sidebar-overlay"
                sx={{
                    position: 'fixed',
                    zIndex: 9998,
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    opacity: 'var(--SideNavigation-slideIn)',
                    backgroundColor: 'var(--joy-palette-background-backdrop)',
                    transition: 'opacity 0.4s',
                    transform: {
                        xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
                        lg: 'translateX(-100%)',
                    },
                }}
                onClick={() => closeSidebar()}
            />
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <IconButton variant="soft" color="primary" size="sm">
                    <BadgeRoundedIcon />
                </IconButton>
                <Typography level="title-lg">Listok</Typography>
                <ColorSchemeToggle sx={{ ml: 'auto' }} />
            </Box>
            <Box
                sx={{
                    minHeight: 0,
                    overflow: 'hidden auto',
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    [`& .${listItemButtonClasses.root}`]: {
                        gap: 1.5,
                    },
                }}
            >
                <List
                    size="sm"
                    sx={{
                        gap: 1,
                        '--List-nestedInsetStart': '30px',
                        '--ListItem-radius': (theme) => theme.vars.radius.sm,
                    }}
                >
                    <ListItem>
                        <ListItemButton onClick={todayListClickHandler} selected={todayListSelected}>
                            <ListItemContent>Today</ListItemContent>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton onClick={inboxListClickHandler} selected={inboxListSelected}>
                            <ListItemContent>Inbox</ListItemContent>
                        </ListItemButton>
                    </ListItem>
                    <ListItem nested>
                        <Toggler defaultExpanded={true}
                            renderToggle={({ open, setOpen }) => (
                                <ListItemButton onClick={() => setOpen(!open)}>
                                    <AssignmentRoundedIcon />
                                    <ListItemContent>
                                        <Typography level="title-sm">Lists</Typography>
                                    </ListItemContent>
                                    <KeyboardArrowDownIcon
                                        sx={{ transform: open ? 'rotate(180deg)' : 'none' }}
                                    />
                                </ListItemButton>
                            )}
                        >
                            <List sx={{ gap: 0.5 }}>
                                {lists?.slice()
                                    .sort((a, b) => a.position - b.position)
                                    .filter(l => !l.main)
                                    .map(list =>
                                        <ListItem
                                            key={list.id}
                                            endAction={
                                                <Chip size="sm" color="neutral" variant="plain">
                                                    {tasks.filter(t => t?.list === list.id && !t.completed).length}
                                                </Chip>
                                            }
                                        >
                                            <ListItemButton
                                                selected={list.id === selectedListId}
                                                onClick={() => chooseListHandler(list)}
                                            >
                                                <ListItemContent>{list.title}</ListItemContent>
                                            </ListItemButton>
                                        </ListItem>
                                    )}
                            </List>
                        </Toggler>
                    </ListItem>
                    <ListItem>
                        <ListItemButton onClick={() => navigate("/reminders")}>
                            <SupportRoundedIcon />
                            Reminders
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton>
                            <SettingsRoundedIcon />
                            Settings
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box>
            <Divider />
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <Avatar
                    variant="outlined"
                    size="sm"
                    src="/listok-logo.png"
                />
                <Box sx={{ minWidth: 0, flex: 1 }}>
                    <Typography level="title-sm">{user?.username}</Typography>
                    <Typography level="body-xs">{user?.email}</Typography>
                </Box>
                <IconButton size="sm" variant="plain" color="neutral">
                    <LogoutRoundedIcon />
                </IconButton>
            </Box>
        </Sheet>
    );
}

export default observer(Sidebar)
