import { Dispatch, Fragment, SetStateAction, useContext, useState } from "react";
import { DateCalendar, LocalizationProvider, TimeField } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import {
    Experimental_CssVarsProvider as MaterialCssVarsProvider,
    experimental_extendTheme as extendMaterialTheme,
    THEME_ID
} from "@mui/material/styles";
import { Button, Checkbox, IconButton, Input, Stack } from "@mui/joy";
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import WbTwilightIcon from '@mui/icons-material/WbTwilight';
import EventIcon from '@mui/icons-material/Event';
import NightlightIcon from '@mui/icons-material/Nightlight';
import Typography from '@mui/joy/Typography';
import TaskStorage, { Task } from "../../../storage/TaskStorage";
import { observer } from "mobx-react-lite";
import { DateTime } from "luxon";

type TaskListDatePickerProps = {
    setDatePickerOpen: Dispatch<SetStateAction<boolean>>
}

function TaskListDatePicker({ setDatePickerOpen }: TaskListDatePickerProps) {

    const [date, setDate] = useState<DateTime | null>(null);
    const [time, setTime] = useState<DateTime | null>(null);
    const { tasks, updateTask, selectedTask } = useContext(TaskStorage)
    const task = tasks.find(t => t.id === selectedTask)
    const [needReminder, setNeedReminder] = useState(task ? (task.reminder ? task.reminder > 0 : false) : false)

    const [repeated, setRepeated] = useState(false)
    const [repeatType, setRepeatType] = useState("")
    const [repeatTime, setRepeatTime] = useState("")

    const dateHandler = (newDate: DateTime | null) => {
        setDate(newDate)
    }

    const timeHandler = (newTime: DateTime | null) => {
        setTime(newTime)
    }

    const clearHandler = () => {
        if (task) {
            if (task.startAt || task.reminder || !task.allDay) {
                const updatedTask: Task = {
                    ...task,
                    startAt: undefined,
                    reminder: undefined,
                    allDay: true
                }
                updateTask(updatedTask)
            }
            setDatePickerOpen(false)
        }
    }

    const handleOk = () => {
        if (task) {
            if (date) {
                const updatedTask: Task = {
                    ...task,
                    startAt: time ? DateTime.fromISO(`${date.toFormat("yyyy-MM-dd")}T${time.toFormat("HH:mm")}`).toMillis() : date?.toMillis(),
                    reminder: needReminder ? 5 : undefined,
                    allDay: time ? false : true,
                    repeated,
                    repeatType,
                    repeatTime
                }
                updateTask(updatedTask)
            } else {
                if (repeated) {
                    const updatedTask: Task = {
                        ...task,
                        startAt: undefined,
                        reminder: needReminder ? 5 : undefined,
                        allDay: time ? false : true,
                        repeated,
                        repeatType,
                        repeatTime
                    }
                    updateTask(updatedTask)
                }
            }
            setDatePickerOpen(false)
        }
    }


    return (
        <Fragment>
            <Stack>
                <Stack direction="row" justifyContent="space-around" sx={{ margin: '4px' }}>
                    <IconButton>
                        <Stack alignItems="center">
                            <WbSunnyIcon sx={{ fontSize: '200%' }} />
                            <Typography level="body-xs">Today</Typography>
                        </Stack>
                    </IconButton>
                    <IconButton>
                        <Stack alignItems="center"><WbTwilightIcon sx={{ fontSize: '200%' }} />
                            <Typography level="body-xs">Tomorrow</Typography>
                        </Stack>
                    </IconButton>
                    <IconButton>
                        <Stack alignItems="center">
                            <EventIcon sx={{ fontSize: '200%' }} />
                            <Typography level="body-xs">Next week</Typography>
                        </Stack>
                    </IconButton>
                    <IconButton>
                        <Stack alignItems="center">
                            <NightlightIcon sx={{ fontSize: '200%' }} />
                            <Typography level="body-xs">Next month</Typography>
                        </Stack>
                    </IconButton>
                </Stack>
                <MaterialCssVarsProvider theme={{ [THEME_ID]: extendMaterialTheme() }}>
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <DateCalendar
                            value={date}
                            onChange={(newDate) => dateHandler(newDate)}
                            sx={{ marginBottom: '-8px' }}
                        />
                    </LocalizationProvider>
                </MaterialCssVarsProvider>
                <Stack spacing={1} sx={{ marginX: '8px' }}>
                    <Button fullWidth color="neutral" variant="outlined">
                        <MaterialCssVarsProvider theme={{ [THEME_ID]: extendMaterialTheme() }}>
                            <LocalizationProvider dateAdapter={AdapterLuxon}>
                                <TimeField
                                    label="Time"
                                    value={time}
                                    onChange={(newTime) => timeHandler(newTime)}
                                    format="HH:mm"
                                />
                            </LocalizationProvider>
                        </MaterialCssVarsProvider>
                    </Button>
                    <Button fullWidth color="neutral" variant="outlined">
                        <Checkbox label="Reminder" checked={needReminder} onChange={() => setNeedReminder(prev => !prev)} />
                    </Button>
                    <Checkbox checked={repeated} onChange={e => setRepeated(e.target.checked)} label="Repeat" />
                    <Input value={repeatType} onChange={e => setRepeatType(e.target.value)} placeholder="DAY" />
                    <Input value={repeatTime} onChange={e => setRepeatTime(e.target.value)} placeholder="11:30" />
                    <Button fullWidth color="neutral" variant="outlined">Repeat</Button>
                </Stack>
                <Stack direction="row" spacing={1} sx={{ margin: '8px', marginTop: '16px' }} justifyContent="space-between">
                    <Button color="neutral" variant="outlined" sx={{ width: '40%' }} onClick={clearHandler}>Clear</Button>
                    <Button color="primary" sx={{ width: '40%' }} onClick={handleOk}>OK</Button>
                </Stack>
            </Stack>
        </Fragment>
    )
}

export default observer(TaskListDatePicker)
