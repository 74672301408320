import { useContext, useEffect, useState } from 'react';
import TaskStorage, { Task } from "../../../storage/TaskStorage";
import { observer } from "mobx-react-lite";
import { Box, Input, Textarea } from '@mui/joy';
import { AppList } from '../../../storage/ListStorage';

function TaskDetailsContent() {

    const { tasks, updateTask, selectedTask } = useContext(TaskStorage)
    const [task, setTask] = useState<Task>(tasks.find(t => t.id === selectedTask)!);
    const [sourceTask, setSourceTask] = useState<Task>(tasks.find(t => t.id === selectedTask)!);
    useEffect(() => {
        setTask(tasks.find(t => t.id === selectedTask)!)
        setSourceTask(tasks.find(t => t.id === selectedTask)!)
    }, [selectedTask]);

    // Debounce
    useEffect(() => {
        if (task === undefined) { // skip initial useEffect
            return
        }
        const timeoutId = setTimeout(() => {
            if (JSON.stringify(sourceTask) !== JSON.stringify(task)) {
                updateTask(task!)
            }
        }, 1000)
        return () => clearTimeout(timeoutId)
    }, [task])

    const handleTextChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        type a = keyof AppList
        setTask({
            ...task,
            [e.target.name]: e.target.value && e.target.value?.toUpperCase()[0] + e.target.value?.slice(1)
        })
    }

    return (
        <Box sx={{ p: 1, display: 'flex', flexDirection: 'column', alignItems: 'start', height:  '100%' }}>
            <Input
                placeholder="Title"
                sx={{
                    width: '100%',
                    background: 'none',
                    border: 'none',
                    boxShadow: 'none',
                    marginBottom: '10px',
                    fontSize: '110%',
                    fontWeight: 'bold',
                    '&:focus-within::before': { boxShadow: 'none' }
                }}
                name='title'
                fullWidth
                value={task?.title}
                onChange={handleTextChange}
            />
            <Textarea
                placeholder="Description"
                className="test-i"
                sx={{
                    width: '100%',
                    minHeight: '100%',
                    background: 'none',
                    border: 'none',
                    boxShadow: 'none',
                    '&:focus-within::before': { boxShadow: 'none' }
                }}
                name='details'
                // minRows={2}
                maxRows={24}
                variant="outlined"
                value={task?.details}
                onChange={handleTextChange}
            />
        </Box>
    );
}

export default observer(TaskDetailsContent)
