import { CssVarsProvider } from '@mui/joy/styles';
import { CssBaseline, Box, Sheet, GlobalStyles, IconButton } from '@mui/joy';
import Sidebar from './components/Sidebar';
import TaskListHeader from './components/TaskListHeader';
import TaskListAndTaskDetailsPane from './components/TaskListAndTaskDetailsPane';
import { toggleSidebar } from '../../utils';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';

export default function Web() {
    return (
        <CssVarsProvider disableTransitionOnChange>
            <CssBaseline />
            <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
                <Sidebar />
                <TaskListHeader />
                <Box component="main" className="MainContent" sx={{ flex: 1 }}>
                    <TaskListAndTaskDetailsPane />
                </Box>
            </Box>
        </CssVarsProvider>
    );
}
