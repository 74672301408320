import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Box, CircularProgress, CssBaseline } from "@mui/joy";
import { CssVarsProvider } from "@mui/joy/styles";
import { Button } from "@mui/material";

export default function Root() {

    const navigate = useNavigate();

    useEffect(() => navigate("/web"))

    return (
        // <CssVarsProvider disableTransitionOnChange>
            <>
                <Button variant="contained">Hello world</Button>;
                <CssBaseline />
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="100vh"
                >
                    <CircularProgress variant="soft" />
                </Box>
            </>
        // </CssVarsProvider>
    )
}
