import { Box, Button, Input, Table } from "@mui/joy";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

type MeterSnapshot = {
    id: string,
    description: string,
    value: number,
    delta: number,
}

type UpdatedMeter = {
    id: string,
    delta: number
}

const Meter = () => {

    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    const code = searchParams.get("code")
    const [meters, setMeters] = useState<MeterSnapshot[]>([])
    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (!code) {
            navigate("/login")
        } else {
            fetch(`https://dailytask.ru/smarthome/api/v1/meters?code=${code}`)
                .then(response => response.json())
                .then(json => setMeters(json))
                .catch(error => console.error(error))
        }
    }, [])

    const updateDeltaForMeter = (e: string, meter: MeterSnapshot) => {
        const updatedMeter: MeterSnapshot = {
            ...meter,
            delta: Number(e)
        }
        setMeters(meters => meters.map(m => m.id !== meter.id ? m : updatedMeter))
    }

    const saveClickButtonHandler = () => {
        const updatedMeters: UpdatedMeter[] = []
        meters.map(meter => updatedMeters.push({ id: meter.id, delta: meter.delta }))
        fetch(`https://dailytask.ru/smarthome/api/v1/meters?code=${code}`, {
            method: 'POST',
            body: JSON.stringify(updatedMeters),
            headers: {
                'content-type': 'application/json',
            },
        })
            .then(response => {
                if (response.ok) {
                    alert("Показания переданы успешно.")
                }
            })
            .catch(error => { console.log(error) })
    }

    return (
        <>
            {
                code && meters.length > 0 &&
                <Box>
                    <Table variant="plain">
                        <thead>
                            <tr>
                                <th>Текущие показания</th>
                                <th>Расход за месяц</th>
                                <th>Новые показания</th>
                                <th>Описание</th>
                            </tr>
                        </thead>
                        <tbody>
                            {meters.map(meter =>
                                <tr key={meter.id}>
                                    <td>{meter.value}</td>
                                    <td>
                                        <Input
                                            type="number"
                                            value={meter.delta}
                                            onChange={e => updateDeltaForMeter(e.target.value, meter)}
                                            slotProps={{
                                                input: {
                                                    ref: inputRef,
                                                    min: 0,
                                                    max: 1000,
                                                    step: 1,
                                                },
                                            }}
                                        />
                                    </td>
                                    <td><Input value={meter.value + meter.delta} disabled variant="plain" sx={{ background: 'none' }} /></td>
                                    <td>{meter.description}</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                    <Button onClick={saveClickButtonHandler} fullWidth sx={{ marginTop: '16px' }} color="success">Сохранить</Button>
                </Box>
            }
        </>
    )
}

export default Meter
