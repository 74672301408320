import { Fragment, useContext } from 'react';
import { toggleMessagesPane } from '../../../utils';
import TaskStorage, { Task } from "../../../storage/TaskStorage";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import { Checkbox, ListDivider, ListItem, ListItemButton, ListItemContent, ListItemDecorator, Typography } from "@mui/joy";

type TaskProps = {
    task: Task
};

function TaskListItem({ task }: TaskProps) {

    const { selectedTask, setSelectedTask, updateTaskStatus } = useContext(TaskStorage)

    return (
        <Fragment>
            <ListItem
                endAction={
                    <Typography level="body-xs" display={{ xs: 'none', md: 'block' }} noWrap>
                        {
                            task && task.startAt
                                ? DateTime.fromMillis(task!.startAt).setLocale('ru').toFormat("LLL dd")
                                : ""
                        }
                    </Typography>
                }
            >
                <ListItemButton variant="plain" selected={task.id === selectedTask}>
                    <ListItemDecorator>
                        <Checkbox
                            variant="outlined"
                            checked={task.completed}
                            onChange={() => updateTaskStatus(task.id)}
                        />
                    </ListItemDecorator>
                    <ListItemContent
                        onClick={() => {
                            toggleMessagesPane();
                            setSelectedTask(task.id);
                        }}
                        sx={{
                            display: '-webkit-box',
                            WebkitLineClamp: '1',
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            fontSize: '94%'
                        }}
                    >
                        {task.title.trim().length > 0 ? task.title : "-"}
                    </ListItemContent>
                </ListItemButton>
            </ListItem>
            <ListDivider inset="context" sx={{ marginTop: "0", marginBottom: "0" }} />
        </Fragment>
    );
}

export default observer(TaskListItem)
