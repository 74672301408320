import { action, computed, makeAutoObservable, observable } from "mobx"
import { createContext } from "react"
import { findCookieByName, gateUrl } from "../utils"
import { DateTime } from "luxon"

export type Task = {
    id: string,
    title: string,
    details: string,
    completed: boolean,
    startAt?: number,
    list: string,
    reminder?: number,
    createdAt: number,
    completedAt?: number,
    chain?: string,
    allDay: boolean,
    price: number,
    repeated: boolean,
    repeatType?: string,
    repeatDaysInWeek?: string,
    repeatDayInMonth?: number,
    repeatMonthInYear?: number,
    repeatTime?: string,
}

// When create task
export type NewTask = {
    id: string,
    title: string,
    details: string,
    list: string,
    startAt?: number,
    allDay: boolean, // default true
    reminder?: number,
    chain?: string,
}

// When update task
export type UpdatedTask = {
    title: string,
    details: string,
    list: string,
    completed: boolean,
    startAt?: number,
    allDay: boolean, // default true
    reminder?: number, // minutes
    price: number, // default 0
    chain?: string,
    repeated: boolean,
    repeatType?: string,
    repeatDaysInWeek?: string,
    repeatDayInMonth?: number,
    repeatMonthInYear?: number,
    repeatTime?: string,
}

class TaskStore {

    constructor() {
        makeAutoObservable(this)
    }

    @observable
    tasks: Task[] = []

    @observable
    selectedTask: string = ""

    @action
    setSelectedTask = (id: string) => {
        this.selectedTask = id;
    }

    @action
    fetchTasks = () => {
        fetch(`${gateUrl}/api/v1/tasks`, {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
                'authorization': 'Bearer ' + findCookieByName('Authorization')
            },
        })
            .then(response => response.json())
            .then(json => this.tasks = json.payload)
            .catch(error => { console.log(error) })
    }

    @action
    addTask = (newTask: NewTask) => {
        // task - add to strorage, newTask - post to backend
        const task: Task = {
            id: newTask.id,
            title: newTask.title,
            details: newTask.details,
            completed: false,
            startAt: newTask.startAt,
            list: newTask.list,
            reminder: newTask.reminder,
            createdAt: Date.now(),
            completedAt: undefined,
            chain: newTask.chain,
            allDay: true,
            price: 0,
            repeated: false,
        }
        this.tasks.push(task)
        fetch(`${gateUrl}/api/v1/tasks`, {
            method: 'POST',
            body: JSON.stringify(newTask),
            headers: {
                'content-type': 'application/json',
                'authorization': 'Bearer ' + findCookieByName('Authorization')
            },
        })
            .then(response => response.json())
            .then(json => this.tasks = this.tasks.map(t => t.id !== task.id ? t : json.payload))
            .catch(error => { console.log(error) })
    }

    @action
    updateTaskStatus = (id: string) => {
        const toCompleted = !this.tasks.find(t => t.id === id)?.completed
        this.tasks = this.tasks.map(task => task.id !== id ? task : { ...task, completed: !task.completed, completedAt: toCompleted ? (DateTime.now().toSeconds() * 1000) : undefined })
        fetch(`${gateUrl}/api/v1/tasks/${id}/complete`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'authorization': 'Bearer ' + findCookieByName('Authorization')
            },
        }).then(_ => this.fetchTasks())
            .catch(error => { console.log(error) })
    }

    @action
    updateTask = (task: Task) => {
        const updateTask: UpdatedTask = {
            title: task.title,
            details: task.details,
            list: task.list,
            completed: task.completed,
            startAt: task.startAt,
            allDay: task.allDay,
            reminder: task.reminder,
            price: task.price,
            chain: task.chain,
            repeated: task.repeated,
            repeatType: task.repeatType,
            repeatDaysInWeek: task.repeatDaysInWeek,
            repeatDayInMonth: task.repeatDayInMonth,
            repeatMonthInYear: task.repeatMonthInYear,
            repeatTime: task.repeatTime,
        }
        this.tasks = this.tasks.map(t => t.id === task.id ? task : t)
        fetch(`${gateUrl}/api/v1/tasks/${task.id}`, {
            method: 'PUT',
            body: JSON.stringify(updateTask),
            headers: {
                'content-type': 'application/json',
                'authorization': 'Bearer ' + findCookieByName('Authorization')
            },
        })
            .then(response => response.json())
            .then(json => this.tasks = this.tasks.map(t => t.id === task.id ? json.payload : t))
            .catch(error => { console.log(error) })
    }

    @action
    deleteTask = (id: string) => {
        this.tasks = this.tasks.filter(task => task.id !== id)
        fetch(`${gateUrl}/api/v1/tasks/${id}`, {
            method: 'DELETE',
            headers: {
                'content-type': 'application/json',
                'authorization': 'Bearer ' + findCookieByName('Authorization')
            },
        })
            .catch(error => { console.log(error) })
    }

    @computed
    get infoTasks() {
        return {
            total: this.tasks.length,
            completed: this.tasks.filter(task => task.completed).length,
            notCompleted: this.tasks.filter(task => !task.completed).length
        }
    }
}

export default createContext(new TaskStore())
