export const gateUrl = 'https://dailytask.ru'
// export const gateUrl = 'http://localhost:8090'

export const findCookieByName = (cookie: string) => {
    const name = cookie + '=';
    const ca = decodeURIComponent(document.cookie).split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return undefined;
}

export const clearCookies = () => {
    document.cookie.split(";").forEach((c) => {
        document.cookie = c.replace(/^ +/, "")
            .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
}

export const addCookie = (cookie: string) => {
    document.cookie = cookie
}

export const openSidebar = () => {
    if (typeof document !== 'undefined') {
        document.body.style.overflow = 'hidden';
        document.documentElement.style.setProperty('--SideNavigation-slideIn', '1');
    }
};

export const closeSidebar = () => {
    if (typeof document !== 'undefined') {
        document.documentElement.style.removeProperty('--SideNavigation-slideIn');
        document.body.style.removeProperty('overflow');
    }
};

export const toggleSidebar = () => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
        const slideIn = window
            .getComputedStyle(document.documentElement)
            .getPropertyValue('--SideNavigation-slideIn');
        if (slideIn) {
            closeSidebar();
        } else {
            openSidebar();
        }
    }
};

export const openMessagesPane = () => {
    if (typeof document !== 'undefined') {
        document.body.style.overflow = 'hidden';
        document.documentElement.style.setProperty('--MessagesPane-slideIn', '1');
    }
};

export const closeMessagesPane = () => {
    if (typeof document !== 'undefined') {
        document.documentElement.style.removeProperty('--MessagesPane-slideIn');
        document.body.style.removeProperty('overflow');
    }
};

export const toggleMessagesPane = () => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
        const slideIn = window
            .getComputedStyle(document.documentElement)
            .getPropertyValue('--MessagesPane-slideIn');
        if (slideIn) {
            closeMessagesPane();
        } else {
            openMessagesPane();
        }
    }
};
